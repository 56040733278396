@import url('https://fonts.googleapis.com/css2?family=Madimi+One&family=Neucha&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Madimi+One&family=Neucha&display=swap');


:root {
  --n1: #A92325;
  --n2: #EC7027;
  --n3: #00b4e6;
  --n4: #2BA5BA;
  --n5: #1D7527;
  --n6: #E31D60;
  --n7: #4B65D6;
  --n8: #D453D4;
  --n9: #9526B3;

  --n3: #bea232;

  --focused: rgb(124, 124, 124);
  --side: rgb(167, 167, 167);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

button {
  font-size: 1em;
  min-width: 3em;
  cursor: pointer;
  color: black;
  border-radius: .4em;
  border: none;
  margin: 1em;
}

.sudoku-game {
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;

  background-color: #28293D;

  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  overflow-x: hidden;
}

.user-button-container {
  width: 100%;
  padding: 1em;
}

.user-button-container div {
  position: absolute;
  top: .2em;
  right: .2em;
}

.title-container {
  width: 100%;
  display: flex;
}

.title {
  font-size: 2em;
  width: max-content;
  margin: auto;
}

.top {
  align-items: center;
  text-align:center;
  width:100%;
  font-size: .8em;
  border: solid white;
  border-radius: 2em;
  margin: auto;
  padding-top: 1em;
  padding-bottom: .5em;
  width: min-content;
  font-size: .7em;
  margin-bottom: 4em;
}

.top div{
  text-align:center;
  display:flexbox;
  margin: auto;
  padding: .1em;
}

.top .inline-divider {
  width: .3em;
  height: 1em;
  margin-left: 1em;
  margin-right: 1em;
  padding: 0;
  border-style: none;
  background-color: white;
  border-radius: 1em;
}

.divider {
  width: max;
  height: .1em;
  margin-left: 1em;
  margin-right: 1em;
  padding: 0;
  border-style: none;
  background-color: white;
  border-radius: 1em;
  margin: 1em 1em .1em 1em;
}

.top .info {
  display: inline-flex;
  margin: 0;
}

.top .menu {
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  padding: 0;
}

.top .menu div {
  padding: 0;
  width: max-content;
}

.game {
  color: white;
}

.board {
  display: grid;
  background-color: rgba(184, 255, 241, 0.448);
  margin: 1em;
  grid-template-columns: repeat(3, 1fr);
  gap: .8em;
  background-color: transparent;
}

.board div{
  /* background-color: blue; */
  border-radius: 0.2em;

}

.section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: .1em;
  padding: 0em;
}

.section button {
  font-size: 1em;
  min-width: auto;
  min-height: auto;
  width: 2em;
  height: 2em;
  background-color: rgb(255, 255, 255);
  padding: 0em;
  margin: 0.1em;
  border-radius: .5em;
  border: none;
  /* transition: all 1s ease-out; */
  transition: all .4s ease-out, background-color .2s ease-out;
  color: white;
}

.section button:hover {
  /* background-color: rgb(0, 0, 145); */
  /* size-adjust: 50%; */
  cursor: pointer;
}

.numbers {
  /* background-color: #2BA5BA; */
  align-items: center;
  position:relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 1em;
  margin-right: 1em;
  padding-top: .4em;
  border-top: solid;
}

.numbers button {
  font-size: 1.3em;
  min-width: 2.4rem;
  min-height: 2.4rem;
  width: 2.3rem;
  height: 2.3rem;
  margin: .2em;
  border-radius: 30%;
  border: none;
  text-align: center;
  margin: auto;
  padding: 0em;
  transition: all 1s ease-out;
  color: white;
}

.numbers button:hover {
  cursor: pointer;
}

[class^="n"].selected{
  font-weight: 800;
  border: .2rem rgb(217, 255, 0);
  border-style: solid;
}

@keyframes flash-red {
  0% {
    background-color: red;
  }
}

[class^="n"] {
  overflow: hidden;
}

[class^="n"].focused {
  background-color: var(--focused);
}

[class^="n"].side {
  background-color: var(--side);
}

button.notes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0;
  padding-left: .2em;
  padding: 0;
}

/* [class^="n"].focused {
  color: white;
} */

button.notes div{
  font-size: .5em;
  padding: 0;
  margin: 0;
  width: 1.35em;
  height: 1.35em;
  border-radius: 0;
}

[class^="n"].complete {
  background-color: transparent;
}

.note-mode {
  margin: auto;
  display: flex;
  padding: 1.2em;
}

.note-mode button {
  margin: auto;
  padding: .3em;
  padding-left: .5em;
  padding-right: .5em;
  border-radius: 1em;
}